import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  About,
  AddensureBlogs,
  AdminMain,
  BlogCreation,
  BlogDetail,
  BlogEdit,
  Carrer,
  Contact,
  Home,
  NotFound,
  Services,
  ThankYou,
} from "./Links";

export default function RouterConfig() {
  return (
    <Router>
      <Routes>
        <Route
          path="/in/home/admin/blogs"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AddensureBlogs />
            </Suspense>
          }
        />

        <Route
          path="/in/home/admin/blogCreations"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BlogCreation />
            </Suspense>
          }
        />

        <Route
          path="/in/home/admin/blogs/blogedit/:id"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BlogEdit />
            </Suspense>
          }
        />

        <Route
          path="/admin-main"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AdminMain />
            </Suspense>
          }
        />

        <Route
          path="/thankyou"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ThankYou />
            </Suspense>
          }
        />

        <Route
          path="/in/services/:serviceType"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Services />
            </Suspense>
          }
        />

        <Route
          path="/in/post/:post"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BlogDetail />
            </Suspense>
          }
        />

        <Route
          path="/in/contactus"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Contact />
            </Suspense>
          }
        />

        <Route
          path="/in/aboutus"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <About />
            </Suspense>
          }
        />

        <Route
          path="/in/careers"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Carrer />
            </Suspense>
          }
        />

        <Route
          path="/"
          element={
            // <Suspense fallback={<div>Loading...</div>}>
            <Home />
            // </Suspense>
          }
        />

        <Route
          path="*"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}
